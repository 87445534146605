import React, { useState, useCallback, useContext, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext, useField } from "formik";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import StyledDropzone from "./StyledDropzone";
import RichText from "./RichText";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import {
  ClipboardCopyIcon,
  CloudDownloadIcon,
  DocumentDownloadIcon,
  DownloadIcon,
} from "@heroicons/react/solid";
import { saveAs } from "file-saver";
import Spinner from "../loaders/Spinner";
import PhoneInput from "react-phone-number-input";
import PhoneNumber from "./PhoneNumber";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatNumber } from "../util/formatter";
import SketchColorPicker from "./SketchColorPicker";
import classNames from "classnames";
import { useTheme } from "styled-components";
import { is } from "immutable";
import { appendQueryToUri } from "../util/generalUtil";
import InfoDescription from "./InfoDescription";
import icons from "../icons";
import { useRowState } from "react-table";

const controlTypes = [
  "button",
  "checkbox",
  "color",
  "date",
  "datetime-local",
  "email",
  "file",
  "hidden",
  "month",
  "password",
  "range",
  "reset",
  "search",
  "submit",
  "tel",
  "text",
  "url",
  "week",
  "serial",
];

export const StyledDate = (props) => {
  let { name, label, validation, type, description } = props;
  const [field, meta, { setValue }] = useField({ name });
  const { values } = useFormikContext();
  const [startDate, setStartDate] = useState(new Date());

  const MinDate = "01-01-1900";

  return (
    <>
      {label && (
        <div className="flex space-x-2">
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}
      {type === "datetime" && (
        <DatePicker
          minDate={new Date(validation?.min ?? MinDate)}
          selected={new Date(field.value)}
          onChange={(date) => setValue(date)}
          timeFormat="HH:mm"
          timeIntervals={30}
          showTimeSelect
          timeCaption="Time"
          dateFormat="M-dd-yyyy, h:mm aa"
          strictParsing
          className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
          portalId="root"
        />
      )}
      {type === "date" && (
        <DatePicker
          minDate={new Date(validation?.min ?? MinDate)}
          selected={new Date(field.value)}
          onChange={(date) => setValue(date)}
          strictParsing
          dateFormat="M-dd-yyyy"
          className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
          portalId="root"
        />
      )}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
      />
    </>
  );
};

const InputControlError = ({ type }) => <h1>Invalid Input type {type}</h1>;

export function LabelField(props) {
  const { name, label, type } = props;

  return (
    <div className="font-semibold mb-2">
      <span>{label}</span>
    </div>
  );
}

export function ColorPicker(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    onChange: onChangeCustom,
    status,
    description,
    ...rest
  } = props;

  const [field, meta, { setValue, setTouched }] = useField({ name });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChange = (color) => setValue(color.hex);

  return (
    <>
      {label && (
        <div className="flex space-x-2">
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}
      <div className="flex relative gap-2 px-3 py-2 items-center border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm">
        <SketchColorPicker
          color={field.value}
          displayColorPicker={displayColorPicker}
          setDisplayColorPicker={setDisplayColorPicker}
          handleChange={handleChange}
        />
        <span className="text-sm">{field.value}</span>
      </div>

      {/* <Field
                autoComplete="off"
                className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                type="text"
                name={name}
                id={name}
                onChange={handleChange}
                placeholder={placeholder || ""} 
                {...rest}
                {...otherProps}
            /> */}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
      />
    </>
  );
}

export function NumberField(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    disabled,
    onChange: onChangeCustom,
    status,
    description,
    showLabel = true,
    indent = "false",
    ...rest
  } = props;
  const [hasError, setError] = useState(null);

  const { setFieldValue } = useFormikContext();

  const handleBlur = (fieldName) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(fieldName);
    }
  };

  const statusVal = (status || {})[name]?.value || null;
  const newProps = (status || {})[name] || {};
  const {
    validation: otherValidation,
    value: otherValue,
    ...otherProps
  } = newProps;

  useEffect(() => {
    if (statusVal != null) {
      setFieldValue(name, parseFloat(statusVal));
    }
  }, [statusVal]);

  const handleChange = (e) => {
    let {
      target: { value },
    } = e;

    if (value === "") {
      setFieldValue(name, value);
      if (typeof onChangeCustom === "function") {
        setTimeout(() => onChangeCustom(e), 300);
      }
      return;
    }

    // Allow the value if it ends with a decimal or a trailing zero
    if (/^\d*\.?\d*$/.test(value)) {
      setFieldValue(name, value);
      if (typeof onChangeCustom === "function") {
        setTimeout(() => onChangeCustom(e), 300);
      }
      return;
    }

    // Format the number if it is valid and does not end with a decimal point or trailing zero
    let val = parseFloat(value.replace(/\,/g, ""));
    if (isNaN(val)) return;

    let formattedValue = formatNumber(val);
    let newValue =
      value.endsWith(".") || value.includes(".0") ? value : formattedValue;

    if (typeof props.publishInputChange === "function") {
      props.publishInputChange(name, newValue);
    } else {
      setFieldValue(name, newValue);
    }

    if (typeof onChangeCustom === "function") {
      console.log("field value", formattedValue, value);
      setTimeout(() => onChangeCustom(e), 300);
    }
  };

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  return (
    <>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}

      <div
        className={`w-full ${
          isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
        }`}
      >
        {isIndentYes &&
          React.createElement(icons["ArrowDownRightIcon"], {
            width: 18,
            height: 18,
            fill: "black",
            stroke: "black",
          })}

        <div className="w-full">
          <Field
            autoComplete="off"
            className={`w-full pl-3 min-w-[12.5rem] placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm`}
            type="text"
            name={name}
            id={name}
            onChange={handleChange}
            placeholder={placeholder || ""}
            disabled={disabled}
            onBlur={() => handleBlur(name)}
            {...otherProps}
          />
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="text-xs text-red-600 mt-1">{msg}</div>
            )}
          />
        </div>
      </div>
    </>
  );
}

export function ServerPropField(props) {
  let {
    name,
    label,
    value,
    onChange: onChangeCustom,
    description,
    showLabel = true,
    indent = "false",
    ...rest
  } = props;
  const [hasError, setError] = useState(null);
  const { setFieldValue } = useFormikContext();

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  description = "The value of this field will be auto-populated";
  return (
    <>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}

      <div
        className={`w-full ${
          isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
        }`}
      >
        {isIndentYes &&
          React.createElement(icons["ArrowDownRightIcon"], {
            width: 18,
            height: 18,
            fill: "black",
            stroke: "black",
          })}

        <div className="w-full">
          <Field
            autoComplete="off"
            className={`pl-3 ${
              !showLabel && "min-w-[12.5rem]"
            } w-full placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm`}
            type="text"
            name={name}
            id={name}
            disabled={true}
          />
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="text-xs text-red-600 mt-1">{msg}</div>
            )}
          />
        </div>
      </div>
    </>
  );
}

export function UrlField(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    status,
    disabled,
    description,
    showLabel = true,
    ...rest
  } = props;
  //   const [hasError,setError] = useState(null);

  const { setFieldValue, values } = useFormikContext();
  const theme = useTheme();
  const [copy, setCopying] = useState(false);

  const prepend = `${origin}/`;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(`${prepend}${values[name]}`);
    setCopying(true);
    setTimeout(() => {
      setCopying(false);
    }, 500);
  };

  return (
    <>
      {label && showLabel && (
        <div className="flex space-x-2">
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}
      <div
        className="flex relative block w-full px-3 py-2 border h-11 border-custom-gray-300 text-gray-500 rounded-md sm:text-sm"
        style={{ backgroundColor: disabled ? "rgba(239,239,239,0.3)" : "none" }}
      >
        <span className="absolute right-2 inset-y-0 flex items-center pl-3 z-10">
          <ClipboardCopyIcon
            onClick={handleCopy}
            className="cursor-pointer h-5 w-5"
            style={{ color: theme?.colors?.primary }}
            aria-hidden="true"
          />
        </span>
        <span className="inline-flex items-center">{prepend}</span>
        <Field
          autoComplete="off"
          className="bg-transparent placeholder-custom-gray-100 placeholder-opacity-40 appearance-none focus:outline-none w-full mr-6"
          type={type}
          name={name}
          id={name}
          disabled={disabled}
          placeholder={placeholder || ""}
        />
      </div>
      {copy && (
        <span className="text-xs text-primary mt-1">
          {name} copied to clipboard!
        </span>
      )}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
      />
    </>
  );
}

export function InputField(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    status,
    disabled,
    description,
    onChange: onChangeCustom,
    showLabel = true,
    indent = "false",
    ...rest
  } = props;
  //   const [hasError,setError] = useState(null);

  const { setFieldValue } = useFormikContext();

  const statusVal = (status || {})[name]?.value || null;
  const newProps = (status || {})[name] || {};
  const {
    validation: otherValidation,
    value: otherValue,
    ...otherProps
  } = newProps;

  console.log("statusVal", statusVal);

  const handleBlur = (fieldName) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(fieldName);
    }
  };

  useEffect(() => {
    if (statusVal != null) {
      setFieldValue(name, statusVal);
    }
  }, [statusVal]);

  const hasError = !controlTypes.includes(type);

  const handleChange = (e) => {
    let {
      target: { value },
    } = e;

    if (typeof props.publishInputChange == "function") {
      props.publishInputChange(name, value);
    } else {
      setFieldValue(name, value);
    }

    if (typeof onChangeCustom === "function") {
      setTimeout(() => onChangeCustom(e), 300);
    }
  };

  const otherFields = {
    serial: "number",
  };

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  console.log("This is an input Field", label, type);
  return (
    <>
      {!hasError && (
        <>
          {label && showLabel && (
            <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
              <label
                className="text-sm font-normal inline-block text-custom-gray-400"
                htmlFor={name}
              >
                {label}{" "}
                {validation?.required == true && (
                  <span className="text-sm text-asterisk-red">&#42;</span>
                )}
              </label>
              {description && (
                <InfoDescription
                  text={description}
                  setIcon={true}
                  name={name}
                />
              )}
            </div>
          )}

          <div
            className={`w-full ${
              isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
            }`}
          >
            {isIndentYes &&
              React.createElement(icons["ArrowDownRightIcon"], {
                width: 18,
                height: 18,
                fill: "black",
                stroke: "black",
              })}

            <div className="w-full">
              <Field
                autoComplete="off"
                className="w-full min-w-[12.5rem] placeholder-custom-gray-100 pl-4 placeholder-opacity-40 h-11 appearance-none relative block px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                type={otherFields[type] || type}
                name={name}
                id={name}
                disabled={disabled}
                onChange={handleChange}
                placeholder={placeholder || ""}
                onBlur={() => handleBlur(name)}
                {...otherProps}
              />
              <ErrorMessage
                name={name}
                render={(msg) => (
                  <div className="text-xs text-red-600 mt-1">{msg}</div>
                )}
              />
            </div>
          </div>
        </>
      )}
      {hasError && <InputControlError type={type} />}
    </>
  );
}

export function FileField(props) {
  const {
    name,
    label,
    value,
    validation,
    options,
    type,
    data,
    target,
    target_id: targetId,
    showLabel = true,
  } = props;

  return <StyledDropzone {...props} showLabel={showLabel} />;
}

export function PhoneField(props) {
  const {
    name,
    label,
    value,
    validation,
    options,
    type,
    data,
    target,
    target_id: targetId,
  } = props;

  return <PhoneNumber {...props} />;
}

export function TextArea(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    disabled,
    onChange: onChangeCustom,
    status,
    description,
    showLabel = true,
    indent = "false",
  } = props;

  // return  <label>
  //         {label}
  //         <textarea
  //         className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
  //         {...props}/>
  //         </label>
  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  return (
    <>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}

      <div className="w-full flex flex-row space-x-2 items-center">
        {isIndentYes &&
          React.createElement(icons["ArrowDownRightIcon"], {
            width: 18,
            height: 18,
            fill: "black",
            stroke: "black",
          })}

        <>
          <textarea
            autoComplete="off"
            className={`${
              isIndentYes ? "w-full " : "w-full"
            } pl-3 min-w-[12.5rem] placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm`}
            type="text"
            {...props}
          />
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="text-xs text-red-600 mt-1">{msg}</div>
            )}
          />
        </>
      </div>
    </>
  );
}

export function FileField2(props) {
  const {
    name,
    label,
    value,
    validation,
    options,
    type,
    data,
    target,
    target_id: targetId,
  } = props;

  return (
    <div class="k-widget k-upload">
      <div class="k-dropzone">
        <div
          role="button"
          class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-upload-button"
          aria-label="Select files..."
          tabindex="0"
        >
          <span class="text-xs">Select files...</span>
        </div>
      </div>
    </div>
  );
}

// const SelectValue = ({children, data}) => {
//     return (
//         <div className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm">
//             {children}
//         </div>
//     );
// }

export function SelectField(props) {
  let {
    name,
    label,
    value,
    validation,
    options,
    type,
    data,
    target,
    target_id: targetId,
    parent,
    description,
    onChange: onChangeCustom,
    useVariableWidth,
    showLabel = true,
    shortenOptions = false,
    disabled,
    indent = "false",
  } = props;

  console.log("select field props", props);
  const isMulti = props.type === "multiselect";
  let selectedValue;
  disabled = disabled ?? false;
  // let maxSelections = false;
  const [maxSelections, setMaxSelections] = useState(false);

  const handleBlur = (fieldName) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(fieldName);
    }
  };

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  const buildData = (options) => {
    if (!Array.isArray(options)) return;
    const isGroupData = options[0]?.group;
    const limiter = options[0].select_mode ? true : false;
    let groupedData = {};

    if (isMulti) {
      if (options[1]?.select_mode) {
        setMaxSelections(true);
      }
    } else {
      if (options[0]?.select_mode) {
        setMaxSelections(true);
      }
    }

    if (isMulti && isGroupData && options?.length > 1 && !limiter) {
      options = [{ label: "All", value: "all", group: "all" }, ...options];
    }

    if (isMulti && !isGroupData && options?.length > 1 && !limiter) {
      options = [{ label: "All", value: "all" }, ...options];
    }

    if (!isGroupData) {
      console.log("returning options", options, name);
      return options;
    }

    console.log("before grouping", options, name);
    options.forEach((option) => {
      groupedData[option.group] = (groupedData[option.group] || []).concat(
        option
      );
    });
    const optionsGroup = Object.entries(groupedData).map(([key, value]) => ({
      label: key,
      options: value,
    }));
    console.log("optionsGroup", optionsGroup, name);
    return optionsGroup;
  };

  // repeat function to build options while searching.....just for grouped options
  const buildData2 = (options) => {
    if (!Array.isArray(options)) return;
    let groupedData = {};
    const isGroupData = options[0]?.group;

    if (!isGroupData) {
      console.log("returning options", options, name);
      return options;
    }

    console.log("before grouping", options, name);
    options.forEach((option) => {
      groupedData[option.group] = (groupedData[option.group] || []).concat(
        option
      );
    });
    const optionsGroup = Object.entries(groupedData).map(([key, value]) => ({
      label: key,
      options: value,
    }));
    console.log("optionsGroup", optionsGroup, name);
    return optionsGroup;
  };

  const [optionsData, setOptionsData] = useState(buildData(options) ?? null);
  const { values } = useFormikContext();

  console.log("props from select field", { props, values });

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [childOptions, setChildOptions] = useState(null);

  const [field, meta, { setValue, setTouched }] = useField(name);
  //console.log("the value =>", name, '---', field.value);

  let { context } = useContext(AppContext);

  const onChange = (e) => {
    setValue(e);
    console.log("setting value here", e);
    if (typeof onChangeCustom === "function") {
      setTimeout(() => onChangeCustom({ target: { name, value: e } }), 300);
    }
  };

  let parentValue = values[parent];
  let parentData = values[parent];
  // parentValue = parentValue?.data
  //   ? { ...parentValue?.value, ...parentValue?.data } || parentValue
  //   : parentValue?.value || parentValue;

  console.log("the parent values", parentData, label);
  console.log("the parent-current value", values[name], name);
  console.log("let see the multi limited", maxSelections);
  parentValue = parentValue?.value || parentValue;

  if (Array.isArray(parentValue)) {
    parentValue = parentValue.map((v) => v?.value || v) || parentValue; //if value array or array of object
  }

  parentData = parentData?.data;

  if (Array.isArray(parentData)) {
    parentData = parentData.map((v) => v?.value || v) || parentData; //if value array or array of object
  }

  const parentValueJson = Array.isArray(parentValue)
    ? JSON.stringify(parentValue)
    : parentValue;

  const parentDataJson = Array.isArray(parentData)
    ? JSON.stringify(parentData)
    : parentData;

  console.log("parentValueJson", parentValueJson, parent);
  console.log("parentDataJson", parentDataJson, parent);

  // const parentValue =  Array.isArray(values[parent]) && values[parent].length > 0 ? values[parent].map(o => o.value) : values[parent]?.value
  // const parentValueJson = Array.isArray(parentValue) ? JSON.stringify(parentValue.filter(m => m)) : parentValue?.toString()

  const payload = {
    targetId: targetId?.toString(),
    target: target?.toString(),
    applicationId: context?.applicationId,
    parentDropValue: parentValueJson,
    parentDropData: parentDataJson,
    jsonString: null,
  };
  //console.log("parentValueJson",parentValueJson)

  const isStrOrNum = (value) => ["number", "string"].includes(typeof value);

  useEffect(() => {
    //console.log("fieldvalue type", field.value == "string", field.value,"optionsData",optionsData)
    if (!data || isStrOrNum(field.value)) {
      let optionItems = [].concat(options || []).concat(optionsData || []);
      let getOption = optionItems.find(({ value }) => field.value == value);

      console.log(
        "what does this useeffect do",
        optionItems,
        getOption,
        optionsData
      );
      console.log("This is called", name);
      getOption && setValue(getOption);
      return;
    }
  }, [field.value]);

  useEffect(() => {
    if (!parentValueJson) return;

    // if (parentValueJson) {
    //   setChildOptions(null);
    // }

    if (["[]", "", "null"].includes(parentValueJson)) return;

    setTimeout(() => {
      if (!loadingOptions) {
        setLoadingOptions(true);
      }

      console.log("the payload", payload, name);
      appFetch
        .get(appendQueryToUri(apiEndpoints.dropdownView, payload))
        .then((result) => {
          console.log("the result of appFetch", result, label);
          setChildOptions(buildData(result));
          setobjectValue(result);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingOptions(false));
    }, 350);
  }, [parentValueJson]);

  const filterResult = (input) => {
    if (input == "") return optionsData;
    // if (input == "") return childOptions;
    if (optionsData[0]?.options) {
      const newArray = [];
      optionsData?.map((item, idx) => {
        item.options.map((items, idx) => {
          newArray.push(items);
        });
      });
      let filterResult = newArray?.filter(
        (i) => i.label.toLowerCase().search(input) != -1
      );
      console.log(filterResult);
      return buildData2(filterResult);
    } else if (!isMulti || optionsData) {
      let filterResult = optionsData?.filter(
        (i) => i.label.toLowerCase().search(input) != -1
      );
      return buildData2(filterResult);
    } else {
      const newArray = [];
      childOptions?.map((item, idx) => {
        item.options.map((items, idx) => {
          newArray.push(items);
        });
      });
      // let filterResult2 = newArray?.slice(1);
      // return setValue(filterResult2);
      let filterResult = newArray?.filter(
        (i) => i.label.toLowerCase().search(input) != -1
      );
      console.log(filterResult);
      return buildData2(filterResult);
    }
  };

  const getSingleValue = () => {
    return field.value instanceof Object ? field.value : null;
  };
  const getMultiValue = () => {
    let defaultValue = field.value;
    let place = Array.isArray(defaultValue)
      ? defaultValue?.find((x) => x.value === "all")
      : defaultValue;
    // console.log(
    //   "defaultValue multi",
    //   defaultValue,
    //   name,
    //   place,
    //   typeof defaultValue
    // );
    // console.log("the children Options", childOptions);
    if (place) {
      let filterResult = null;

      if (optionsData) {
        filterResult = optionsData?.slice(1);
      } else {
        filterResult = childOptions?.slice(1);
      }
      // console.log("the filtered data ish", childOptions, optionsData);
      // console.log(
      //   "the filtered resulys for dropdown and childoptions",
      //   filterResult,
      //   childOptions
      // );
      if (childOptions) {
        const newArray = [];
        childOptions[0]?.options &&
          childOptions?.map((item, idx) => {
            item?.options.map((items, idx) => {
              newArray.push(items);
            });
          });
        let filterResult2 =
          newArray.length > 0 ? newArray?.slice(1) : filterResult;
        return setValue(filterResult2);
      }

      return setValue(filterResult);
    } else {
      try {
        if (typeof defaultValue === "string") {
          defaultValue = JSON.parse(defaultValue);
        }
      } catch {}

      return defaultValue
        ? defaultValue?.filter(({ label }) => label)
        : defaultValue;
    }
  };

  selectedValue = isMulti ? getMultiValue() : getSingleValue();

  console.log("selectedValue", name, label, selectedValue, maxSelections);
  console.log("setMaxSelections", maxSelections, name);

  const setobjectValue = (result) => {
    let defaultValue = field.value;
    try {
      if (typeof defaultValue === "string") {
        defaultValue = JSON.parse(defaultValue);
      }
    } catch {}

    defaultValue = defaultValue?.value || defaultValue;
    if (Array.isArray(defaultValue)) {
      defaultValue = defaultValue.map((v) => v?.value || v);
    }
    // console.log("this is called defaultValue", defaultValue);

    console.log("is multi", isMulti);
    let selectedOption = isMulti
      ? result.filter((o) =>
          defaultValue?.map((m) => m.toString()).includes(o.value.toString())
        )
      : result.find((o) => o.value == defaultValue);

    // console.log("this is called selectedOption", selectedOption);
    //checkback on this - potential conflick on autoselect option functionalities
    // if (
    //   (Array.isArray(selectedOption) && selectedOption.length == 0) ||
    //   !selectedOption
    // ) {
    //   //search by name
    //   selectedOption = isMulti
    //     ? result.filter((o) =>
    //         defaultValue?.map((m) => m.toString()).includes(o.name)
    //       )
    //     : result.find((o) => o.name == defaultValue);
    // }

    console.log("defaultValue", { defaultValue, result });

    if (
      selectedOption?.constructor === {}.constructor ||
      selectedOption?.length > 0
    ) {
      console.log("this is called", name, selectedOption);
      setValue(selectedOption);

      if (typeof onChangeCustom === "function") {
        setTimeout(
          () => onChangeCustom({ target: { name, value: selectedOption } }),
          300
        );
        return;
      }
      return;
    }

    console.log("the results are", result, parent, name);
    //auto select if length is 1
    if (result?.length === 1) {
      // Only auto-select if there's a single option
      if (validation?.required) {
        selectedOption = isMulti ? result : result[0];
        if (typeof onChangeCustom === "function") {
          setTimeout(
            () => onChangeCustom({ target: { name, value: selectedOption } }),
            300
          );
          return;
        }
        console.log("This is called", name);
        setValue(selectedOption);
      }
      return;
    }

    if (result?.length == 0 || parent) {
      let valueSelected = type == "multiselect" ? [] : null;
      if (typeof onChangeCustom === "function") {
        setTimeout(
          () => onChangeCustom({ target: { name, value: valueSelected } }),
          300
        );
        return;
      }
      console.log("this is called", name);
      // setValue(valueSelected);
    }
  };

  const loadDataOptions = (input) =>
    new Promise((resolve) => {
      if (optionsData != null) resolve(filterResult(input));

      if (parent && !parentValue) {
        resolve(null);
        return;
      }

      appFetch
        .get(appendQueryToUri(apiEndpoints.dropdownView, payload))
        .then((result) => {
          // console.log(result);
          console.log("the result of appFetch loadDataOptions", result, label);
          resolve(buildData(result));
          setOptionsData(buildData(result));

          setobjectValue(result);
        })
        .catch((e) => console.log(e.message));
    });

  const handleFocus = () => {
    if (
      !parentValue ||
      ["[]", "", "null"].includes(parentValueJson) ||
      loadingOptions
    )
      return;
    if (childOptions != null) return;
    if (Object.keys(field.value ?? {})?.length !== 0) return;

    setLoadingOptions(true);

    appFetch
      .get(appendQueryToUri(apiEndpoints.dropdownView, payload))
      .then((result) => {
        console.log("the result of appFetch handleFocus", result, label);
        setChildOptions(buildData(result));
        setobjectValue(result);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoadingOptions(false));
  };

  const updateValueAndTriggerChange = (value) => {
    if (typeof props.publishInputChange === "function") {
      props.publishInputChange(name, value);
    } else {
      onChange(value);
    }
  };

  const handleSelectChange = (newValue) => {
    if (!Array.isArray(newValue)) {
      // Reset if the new value is not an array
      updateValueAndTriggerChange(newValue);
      return;
    }

    // Limit the number of selections if maxSelections is defined
    const limitedValue =
      maxSelections === true
        ? newValue.slice(-1) // Keep only the last `maxSelections` items
        : newValue;

    updateValueAndTriggerChange(limitedValue);
  };

  const myData = optionsData ?? childOptions;
  // console.log("my data bla bla bla", myData);
  // console.log("The selected value is ", selectedValue);
  let placeHolder =
    (data && myData == null && !parent) || loadingOptions
      ? "Loading..."
      : isMulti
      ? `Select Options`
      : `Select Option`;
  const selectProps = {
    defaultValue: selectedValue,
    value: selectedValue,
    onChange: handleSelectChange,
    //onBlur: setTouched,
    onFocus: handleFocus,
    placeholder: placeHolder,
    classNamePrefix: "custom-select",
    className:
      "overflow-auto placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full border border-custom-gray-300 text-gray-500 rounded-md outline-none focus:outline-none sm:text-sm",
    isMulti: isMulti,
    autosize: true,
    menuPlacement: "auto",
    menuPortalTarget: document.body,
    styles: {
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      control: (css) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
      }),
      option: (css) => ({ ...css, paddingRight: 36 + 8 }),
    },
  };

  if (shortenOptions) {
    selectProps.className +=
      " max-h-[45px] scrollbar scrollbar-thin scrollbar-w-1 scrollbar-thumb-gray-200 scrollbar-thumb-rounded-full";
  }

  if (!useVariableWidth) {
    delete selectProps["styles"]["control"];
    delete selectProps["styles"]["option"];
  }

  console.log("the label ish", childOptions);
  console.log("OptionsData", optionsData);
  // arrayLength == 1 && setDefaultVal(childOptions)

  console.log(
    "lets see the difference",
    parent,
    childOptions,
    selectedValue,
    name
  );
  return (
    <>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}

      <div
        className={`w-full ${
          isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
        }`}
      >
        {isIndentYes &&
          React.createElement(icons["ArrowDownRightIcon"], {
            width: 18,
            height: 18,
            fill: "black",
            stroke: "black",
          })}
        <>
          <div className={`${!showLabel ? "min-w-[200px]" : "w-full"}`}>
            {data ? (
              parent ? (
                <AsyncSelect
                  // cacheOptions
                  defaultOptions={childOptions}
                  loadOptions={loadDataOptions}
                  isLoading={loadingOptions}
                  onBlur={() => handleBlur(name)}
                  isDisabled={disabled}
                  {...selectProps}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  isLoading={loadingOptions}
                  loadOptions={loadDataOptions}
                  isDisabled={disabled}
                  onBlur={() => handleBlur(name)}
                  {...selectProps}
                />
              )
            ) : (
              <Select
                onBlur={() => handleBlur(name)}
                options={buildData(options)}
                isDisabled={disabled}
                {...selectProps}
              />
            )}
            {/* {meta.touched && meta.error ? (
                  <div className="form-text text-danger">{meta.error}</div>
                  ) : null} */}
          </div>
          {/* <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} /> */}
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="text-xs text-red-600 mt-1">{msg}</div>
            )}
          />
        </>
      </div>
    </>
  );
}

export function RichTextField(props) {
  const {
    name,
    label,
    options,
    value,
    validation,
    type,
    showLabel = true,
  } = props;
  return <RichText {...props} />;
}

// export function RadioField(props) {
//   const {
//     name,
//     label,
//     options,
//     value,
//     validation,
//     type,
//     target_id,
//     checked,
//     status,
//     description,
//     onChange: onChangeCustom,
//     showLabel = true,
//     ...rest
//   } = props;

//   const { setFieldValue } = useFormikContext();
//   const val = (status || {})[name]?.value;
//   useEffect(() => {
//     //   if(val){
//     setFieldValue(name, val);
//     //   }
//   }, [val]);

//   const handleChange = (e) => {
//     let {
//       target: { value },
//     } = e;

//     setFieldValue(name, value);
//     if (typeof onChangeCustom === "function") {
//       setTimeout(() => onChangeCustom(e), 300);
//     }
//   };

//   useEffect(() => {
//     if (checked) {
//       setFieldValue(name, checked);
//       if (typeof onChangeCustom === "function") {
//         setTimeout(
//           () => onChangeCustom({ target: { name, value: checked } }),
//           300
//         );
//       }
//     }
//   }, []);

//   return (
//     <>
//       <div>
//         {label && showLabel && (
//           <div className="flex space-x-2">
//             <label
//               className="text-sm font-normal inline-block text-custom-gray-400"
//               htmlFor={name}
//             >
//               {label}{" "}
//               {validation?.required == true && (
//                 <span className="text-sm text-asterisk-red">&#42;</span>
//               )}
//             </label>
//             {description && (
//               <InfoDescription text={description} setIcon={true} name={name} />
//             )}
//           </div>
//         )}
//         <div class="flex gap-2">
//           {options?.map(({ label, value }, idx) => (
//             <div className="flex items-center" key={`${target_id}_${idx}`}>
//               <Field
//                 id={`${name}_${idx}`}
//                 name={name}
//                 value={value}
//                 type="radio"
//                 className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-custom-gray-200 rounded"
//                 onChange={handleChange}
//                 {...rest}
//               />
//               <label htmlFor={`${name}_${idx}`} className="ml-1 block text-sm">
//                 {label}
//               </label>
//             </div>
//           ))}
//         </div>
//       </div>
//       {/* <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} /> */}
//       <ErrorMessage
//         name={name}
//         render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
//       />
//     </>
//   );
// }

export const DownloadField = (props) => {
  const [downloading, setDownloading] = useState(false);

  const downloadItems = (files) => {
    files = (files ?? []).filter((uri) => uri);
    if (files.length == 0) return;

    setDownloading(true);

    setTimeout(() => {
      files.forEach((uri, idx) => {
        var filename = uri.split("/").pop();
        saveAs(uri, filename);
        console.log("equal length", idx + 1 === files.length);
        if (idx + 1 === files.length) {
          setDownloading(false);
        }
      });
    }, 700);
  };
  return (
    <div className="inline-grid place-items-center w-full cursor-pointer">
      {downloading ? (
        <Spinner wdith={24} height={24} fill="#c2c2c2" />
      ) : (
        <DocumentDownloadIcon
          width={24}
          height={24}
          onClick={() => downloadItems(props.value)}
        />
      )}
    </div>
  );
};

export function InputField2(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    description,
    ...rest
  } = props;
  const [hasError, setError] = useState(null);

  const handleBlur = (fieldName) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(fieldName);
    }
  };

  try {
    if (!controlTypes.includes(type)) {
      throw new Error("invalid type");
    }
  } catch (error) {
    hasError == null && setError(true);
  }
  return (
    <>
      {!hasError && (
        <>
          {label && (
            <div className="flex space-x-2">
              <label
                className="text-sm font-normal inline-block text-custom-gray-400"
                htmlFor={name}
              >
                {label}{" "}
                {validation?.required == true && (
                  <span className="text-sm text-asterisk-red">&#42;</span>
                )}
              </label>
              {description && (
                <InfoDescription
                  text={description}
                  setIcon={true}
                  name={name}
                />
              )}
            </div>
          )}
          <Field
            //   className="form-control border h-10"
            autoComplete="off"
            className="pl-3 min-w-[10rem] placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none text-xs"
            style={{ maxWidth: 80 }}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder || ""}
            onBlur={() => handleBlur(name)}
            {...rest}
          />
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div
                className="text-xs text-red-600 mt-1"
                style={{ fontSize: "0.6rem" }}
              >
                {msg}
              </div>
            )}
          />
        </>
      )}
      {hasError && <InputControlError type={type} />}
    </>
  );
}

export function SelectField2(props) {
  const { name, label, options, value, validation } = props;
  console.log("name, label, options,value", { name, label, options, value });
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    description,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = useFormikContext();
  return (
    <>
      {label && (
        <div className="flex space-x-2">
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}
      <Field
        as="select"
        className="border px-2 placeholder-custom-gray-100 placeholder-opacity-40 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none text-xs"
        id={name}
        name={name}
        value={values[name]?.toLowerCase()}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {options?.map((optn, idx) => (
          <option
            key={idx}
            value={optn.value.toLowerCase()}
            label={optn.label || optn.value}
          />
        ))}
      </Field>
      {/* <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} /> */}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
      />
    </>
  );
}

export function LocationField(props) {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    validation,
    description,
    showLabel = true,
    indent = "false",
    ...rest
  } = props;

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const { setFieldValue, values } = useFormikContext();

  const handleBlur = (fieldName) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(fieldName);
    }
  };
  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          let location = `${position.coords.latitude}, ${position.coords.longitude}`;
          setFieldValue(name, location);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  const getLocation = () => {
    if (location.longitude != null) {
      console.log("this change is made");
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          let location = `${position.coords.latitude}, ${position.coords.longitude}`;
          setFieldValue(name, location);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  let stringres = `${location?.longitude}, ${location?.latitude}`;
  console.log("the location component", location, error, stringres);
  console.log("the current values", values);

  return (
    <>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}{" "}
            {validation?.required == true && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
        </div>
      )}
      {
        // <div className="flex min-w-[12.5rem] placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none w-full   border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm">
        //   <div className="flex-1 py-2 px-3">
        //     {location.latitude == null ? (
        //       <div>
        //         <span>Click button to get Current Location</span>
        //       </div>
        //     ) : (
        //       !error && (

        //         <div>
        //           <span>
        //             Current Location is: {location.latitude},{" "}
        //             {location.longitude}
        //           </span>
        //         </div>
        //       )
        //     )}
        //   </div>
        //   <div
        //     onClick={() => {
        //       geLocation();
        //     }}
        //   >
        //     <button className="px-3  justify-left bg-primary text-white h-11 rounded-r-md">
        //       Get Location
        //     </button>
        //   </div>
        // </div>
        <div
          className={`w-full ${
            isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
          }`}
        >
          {" "}
          {isIndentYes &&
            React.createElement(icons["ArrowDownRightIcon"], {
              width: 18,
              height: 18,
              fill: "black",
              stroke: "black",
            })}
          <>
            <Field
              autoComplete="off"
              className="pl-3 flex-1 min-w-[12.5rem] placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-l-md focus:outline-none sm:text-sm"
              type={type}
              name={name}
              id={name}
              disabled={true}
              value={stringres}
              placeholder={placeholder || ""}
              onBlur={() => handleBlur(name)}
              {...props}
            />
            <div
              onClick={() => {
                getLocation();
              }}
              className="px-3 justify-left bg-primary text-white h-11 rounded-r-md cursor-pointer"
            >
              <p className="pt-[10px]">Get Location</p>
            </div>
          </>
        </div>
      }
    </>
  );
}

export function CheckboxField(props) {
  const {
    name,
    label,
    options,
    validation,
    target_id,
    status,
    description,
    onChange: onChangeCustom,
    showLabel = true,
    indent = "false",
    ...rest
  } = props;

  const { setFieldValue, values } = useFormikContext();
  const selectedValues = values[name] || [];

  const [optionsData, setOptionsData] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  let { context } = useContext(AppContext);

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  // Payload setup for fetching options
  const payload = {
    targetId: props?.target_id,
    target: props?.target,
    applicationId: context?.applicationId,
  };

  // Fetch options or use provided ones
  useEffect(() => {
    if (options) {
      setOptionsData(options);
    } else {
      setLoadingOptions(true);
      appFetch
        .get(appendQueryToUri(apiEndpoints.dropdownView, payload))
        .then((result) => {
          console.log("fetch options for checkbox", result);
          setOptionsData(result);
        })
        .catch((e) => console.log(e.message))
        .finally(() => setLoadingOptions(false));
    }
  }, [target_id]);

  // Set initial values if not set yet
  useEffect(() => {
    if (!values[name]) {
      const initialSelectedValues = selectedValues
        .map((val) => {
          const option = optionsData.find((opt) => opt.value === val);
          return option ? { label: option.label, value: option.value } : null;
        })
        .filter(Boolean);

      setFieldValue(name, initialSelectedValues);
    }
  }, [name, setFieldValue, selectedValues, optionsData, values]);

  const handleChange = (e) => {
    const {
      target: { value, checked },
    } = e;

    // Find the corresponding option
    const option = optionsData.find((opt) => opt.value === value);

    if (!option) return;

    // Create a new value object
    const newValue = { label: option.label, value: option.value };

    // Check if the value is already selected
    const isAlreadySelected = selectedValues.some((v) => v.value === value);

    // Update the selected values array
    const newValues = checked
      ? isAlreadySelected
        ? selectedValues // Avoid duplicates if already selected
        : [...selectedValues, newValue] // Add new object
      : selectedValues.filter((v) => v.value !== value); // Remove object if unchecked

    setFieldValue(name, newValues);

    // Trigger custom onChange if provided
    if (typeof onChangeCustom === "function") {
      setTimeout(() => onChangeCustom(e), 300);
    }
  };

  return (
    <>
      <div>
        {label && showLabel && (
          <div className={`${isIndentYes ? "ml-12" : ""} flex space-x-2 pb-1`}>
            <label
              className="text-sm font-normal inline-block text-custom-gray-400"
              htmlFor={name}
            >
              {label}{" "}
              {validation?.required && (
                <span className="text-sm text-asterisk-red">&#42;</span>
              )}
            </label>
            {description && (
              <InfoDescription text={description} setIcon={true} name={name} />
            )}
          </div>
        )}

        <hr className="border-t-0 border border-b-2 border-gray-300 mt-1 mb-2" />

        <div
          className={`w-full ${
            isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
          }`}
        >
          {" "}
          {isIndentYes &&
            React.createElement(icons["ArrowDownRightIcon"], {
              width: 18,
              height: 18,
              fill: "black",
              stroke: "black",
            })}
          <>
            {loadingOptions ? (
              <p>Loading...</p>
            ) : (
              optionsData.map((option) => (
                <div
                  key={option.value}
                  className="flex items-center space-x-2 text-gray-500"
                >
                  <input
                    type="checkbox"
                    id={`${name}_${option.value}`}
                    name={name}
                    value={option.value}
                    checked={selectedValues.some(
                      (v) => v.value === option.value
                    )}
                    onChange={handleChange}
                    {...rest}
                  />
                  <label
                    htmlFor={`${name}_${option.value}`}
                    className="text-sm text-custom-gray-600"
                  >
                    {option.label}
                  </label>
                </div>
              ))
            )}
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="text-xs text-red-600 mt-1">{msg}</div>
              )}
            />
          </>
        </div>
      </div>
    </>
  );
}

// export function RadioField(props) {
//   const {
//     name,
//     label,
//     options,
//     validation,
//     target_id,
//     status,
//     description,
//     onChange: onChangeCustom,
//     showLabel = true,
//     ...rest
//   } = props;

//   const { setFieldValue, values } = useFormikContext();
//   const selectedValue = values[name] || "";

//   console.log("the value of radio button is", values);

//   // Set initial value if it hasn't been set before
//   useEffect(() => {
//     if (!values[name]) {
//       setFieldValue(name, selectedValue);
//     }
//   }, [name, setFieldValue, selectedValue, values]);

//   const handleChange = (e) => {
//     const {
//       target: { value },
//     } = e;

//     // Update the selected value directly, as only one option is allowed
//     setFieldValue(name, value);

//     // Trigger custom onChange if provided
//     if (typeof onChangeCustom === "function") {
//       setTimeout(() => onChangeCustom(e), 300);
//     }
//   };

//   return (
//     <>
//       <div>
//         {label && showLabel && (
//           <div className="flex space-x-2 pb-1">
//             <label
//               className="text-sm font-normal inline-block text-custom-gray-400"
//               htmlFor={name}
//             >
//               {label}{" "}
//               {validation?.required && (
//                 <span className="text-sm text-asterisk-red">&#42;</span>
//               )}
//             </label>
//             {description && (
//               <InfoDescription text={description} setIcon={true} name={name} />
//             )}
//           </div>
//         )}

//         {/* <hr className="border-t-0 border-dashed border-b-2 border-gray-300 my-2" /> */}

//         <div className="flex gap-4">
//           {options?.map(({ label, value }, idx) => (
//             <div className="flex items-center" key={`${target_id}_${idx}`}>
//               <Field
//                 id={`${name}_${idx}`}
//                 name={name}
//                 value={value}
//                 type="radio"
//                 checked={selectedValue === value}
//                 className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-custom-gray-200 rounded"
//                 onChange={handleChange}
//                 {...rest}
//               />
//               <label
//                 htmlFor={`${name}_${idx}`}
//                 className="ml-1 block text-sm text-custom-gray-600"
//               >
//                 {label}
//               </label>
//             </div>
//           ))}
//         </div>
//       </div>
//       <ErrorMessage
//         name={name}
//         render={(msg) => <div className="text-xs text-red-600 mt-1">{msg}</div>}
//       />
//     </>
//   );
// }

export function RadioField(props) {
  const {
    name,
    label,
    options,
    validation,
    target_id,
    status,
    description,
    onChange: onChangeCustom,
    checked, // Use checked prop for initial default
    showLabel = true,
    indent = "false",
    ...rest
  } = props;

  const { setFieldValue, values } = useFormikContext();
  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  // Set the default selected value in the format { label: "", value: "" }
  const defaultSelectedValue =
    options?.find((opt) => opt.value === checked) || null;
  const selectedValue = values[name] ||
    defaultSelectedValue || { label: "", value: "" };

  const [optionsData, setOptionsData] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const { context } = useContext(AppContext);

  // Payload setup for fetching options
  const payload = {
    targetId: target_id,
    target: props?.target,
    applicationId: context?.applicationId,
  };

  // Fetch options or use provided ones
  useEffect(() => {
    if (options) {
      setOptionsData(options);
    } else {
      setLoadingOptions(true);
      appFetch
        .get(appendQueryToUri(apiEndpoints.dropdownView, payload))
        .then((result) => setOptionsData(result))
        .catch((e) => console.log(e.message))
        .finally(() => setLoadingOptions(false));
    }
  }, [target_id, options, payload]);

  // Set initial value if `checked` is provided and `values[name]` is unset
  useEffect(() => {
    if (!values[name] && checked && defaultSelectedValue) {
      // setFieldValue(name, {
      //   label: defaultSelectedValue.label,
      //   value: defaultSelectedValue.value,
      // });

      setFieldValue(name, defaultSelectedValue.value);
    }
  }, [checked, defaultSelectedValue, name, setFieldValue, values]);

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOption = optionsData.find((opt) => opt.value === value);

    if (selectedOption) {
      // Set value in the format { label: "", value: "" }
      // setFieldValue(name, {
      //   label: selectedOption.label,
      //   value: selectedOption.value,
      // });
      setFieldValue(name, selectedOption.value);

      // Trigger custom onChange if provided
      if (typeof onChangeCustom === "function") {
        setTimeout(() => onChangeCustom(e), 300);
      }
    }
  };

  console.log("the current radiofield props", props);
  console.log("the current radiofield form values", values);
  console.log("the current radiofield selectedValue", selectedValue);

  return (
    <div>
      {label && showLabel && (
        <div className={`${isIndentYes ? "ml-7" : ""} flex space-x-2 pb-1`}>
          <label
            className="text-sm font-normal inline-block text-custom-gray-400"
            htmlFor={name}
          >
            {label}
            {validation?.required && (
              <span className="text-sm text-asterisk-red">&#42;</span>
            )}
          </label>
          {description && (
            <InfoDescription text={description} setIcon={true} name={name} />
          )}
        </div>
      )}

      <hr className="border-t-0 border border-b-2 border-gray-300 mt-1 mb-2" />

      <div
        className={`w-full ${
          isIndentYes ? "flex flex-row space-x-3 items-center" : "block"
        }`}
      >
        {isIndentYes &&
          React.createElement(icons["ArrowDownRightIcon"], {
            width: 18,
            height: 18,
            fill: "black",
            stroke: "black",
          })}
        <>
          {loadingOptions ? (
            <p>Loading...</p>
          ) : (
            optionsData.map((option) => (
              <div
                key={option.value}
                className="flex items-center space-x-2 text-gray-500"
              >
                <input
                  type="radio"
                  id={`${name}_${option.value}`}
                  name={name}
                  value={option.value}
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                  {...rest}
                />
                <label
                  htmlFor={`${name}_${option.value}`}
                  className="text-sm text-custom-gray-600"
                >
                  {option.label}
                </label>
              </div>
            ))
          )}
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="text-xs text-red-600 mt-1">{msg}</div>
            )}
          />
        </>
      </div>
    </div>
  );
}
