import React, { useContext, useEffect, useState } from "react";
import { appendQueryToUri, groupBy } from "../util/generalUtil";
import TransformValue from "./TransformValue";
import classNames from "classnames";
import WithLoader from "./WithLoader";
import Select from "react-select";
import styled from "styled-components";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import Spinner from "../loaders/Spinner";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import DataTable1 from "./DataTable1";
import moment from "moment";
import { CalendarIcon, DownloadIcon } from "@heroicons/react/solid";
import ReactDatePicker from "react-datepicker";

window._moment = moment;

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-60 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No record available</h2>
      <p className="text-sm text-gray-700">Check back in a bit!</p>
    </div>
  );
};

const ReportOption = ({ innerProps, data, children, label }) => {
  return (
    <div className="custom-select-option" {...innerProps}>
      {children}
    </div>
  );
};

const ReportOptionValue = ({ children, data }) => {
  return <div className="custom-select-option-value">{children}</div>;
};

const FilterItem = styled.div`
  background: ${({ theme }) => theme.colors.primary};
`;

const TagFilterItem = ({ active, period, onClick }) => (
  <FilterItem
    className={classNames("tag filter", { active: active })}
    onClick={onClick}
  >
    {period}
  </FilterItem>
);

function DateNotificationList1({ setLoading, name, options, children }) {
  const {
    context: { menuId },
  } = useContext(AppContext);
  const [dateRange, setDateRange] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const [filterDateRange, setFilterDateRange] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  const currentYear = new Date().getFullYear();

  const minDate = new Date(currentYear - 1, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  console.log(options);
  console.log(children);

  const reportOptions =
    options?.map(({ period, grid, ...rest }) => ({
      ...rest,
      label: rest.name,
    })) || [];
  /* const form = children[0]?.props?.children?.props; */

  const [loadingPeriodData, setLoadingPeriodData] = useState(false);

  //set state in effect
  const [activeReport, setActiveReport] = useState(null);

  // const data = options?.find(o => activeReport?.name === o.name)
  // const periodTypeGroup = groupBy(data?.period || [], "period_type");
  // const periodTypes = Object.keys(periodTypeGroup);

  const [periodTypeGroup, setPeriodTypeGroup] = useState(null);
  const [periodTypes, setPeriodTypes] = useState(null);

  console.log("reportOptions === ", {
    activeReport,
    periodTypeGroup,
    periodTypes,
  });

  // const [activePeriod, setActivePeriod]  = useState(periodTypeGroup[activePeriodType]?.slice(-1)?.at(0))
  // const [activePeriodType,setActivePeriodType] = useState(periodTypes[0])
  // const [dataGrid,setDataGrid] = useState(data?.grid?.filter(o => o.ID == activePeriod?.ID));

  const [activePeriod, setActivePeriod] = useState(null);
  const [activePeriodType, setActivePeriodType] = useState(null);
  const [dataGrid, setDataGrid] = useState(null);
  const [dataGridRes, setDataGridRes] = useState(null);
  /* const {excel_template, name:templateName, target_id} = form; */
  const [activePage, setActivePage] = useState(0); // Track the active page (0-based index)

  useEffect(() => {
    if (menuId) {
      let activeReport = reportOptions[0] ?? null;

      let data = options?.find((o) => activeReport.name === o.name);
      let periodTypeGroup = groupBy(
        data?.period || [],
        (item) => item.period_type
      );
      let periodTypes = Object.keys(periodTypeGroup);

      let activePeriodType = periodTypes[0];
      let activePeriod = periodTypeGroup[activePeriodType]?.slice(-1)?.at(0);

      setPeriodTypeGroup(periodTypeGroup);
      setPeriodTypes(periodTypes);

      setActiveReport(activeReport);
      setActivePeriodType(activePeriodType);
      setActivePeriod(activePeriod);
      setDataGrid(data?.grid?.filter((o) => o.ID == activePeriod?.ID));

      fetchActivePeriodData(activePeriod, activeReport);

      console.log("reportOptions", {
        activeReport,
        periodTypeGroup,
        periodTypes,
        activePeriod,
        dataGrid,
        data,
        options,
      });
    }
  }, [menuId]);

  // console.log({periodTypeGroup,periodTypes,activePeriod,dataGrid,data})

  let gridKeys = Object.keys((dataGrid ?? [])[0] || {})?.filter(
    (head) => !["ID", "drillable", "drillid", "targetid"].includes(head)
  );

  const filterByDateRange = (dateFilter) => {
    setLoadingPeriodData(true);

    setCurrentDate(dateFilter);

    let postData = {
      layoutId: activeReport?.layoutid,
      schemaId: activePeriod?.ID,
      target: activeReport?.target,
      targetId: activeReport?.targetid,
      dateFilter: dateFilter ? JSON.stringify(dateFilter) : null,
    };

    console.log("the filterbyDate postDATA", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("fetch active period data", res, postData);
        let grid = (res?.options || [])[0]?.grid || [];
        let options = (res?.options || [])[0] || [];
        setDataGridRes(options);
        setActivePage(0);
        console.log("datagridresponse", options);
        setDataGrid(grid);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoadingPeriodData(false));
  };

  const fetchActivePeriodData = (period, reportType) => {
    setLoadingPeriodData(true);
    let postData = {
      layoutId: reportType?.layoutid,
      schemaId: period?.ID,
      target: reportType?.target,
      targetId: reportType?.targetid,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("fetch active period data", res, postData);
        let grid = (res?.options || [])[0]?.grid || [];
        let options = (res?.options || [])[0] || [];
        setActivePage(0);
        setDataGridRes(options);
        console.log("datagridresponse", options);
        setDataGrid(grid);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoadingPeriodData(false));
  };

  const exportData = async () => {
    console.log({ activeReport, activePeriod });
    setLoading(true);
    let postData = {
      urlMenu: null,
      layoutId: activeReport?.layoutid,
      targetId: activeReport?.targetid,
      target: activeReport?.target,
      schemaId: activePeriod?.ID,
      txnGroup: null,
      parentDropValue: null,
      drillId: null,
      targetName: null,
      targetType: null,
      outputType: "DATASET",
      dateFilter: currentDate ? JSON.stringify(currentDate) : null,
    };

    let token = localStorage.getItem("token") ?? "";
    fetch(appendQueryToUri(apiEndpoints.downloadData, postData), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${activeReport.name?.replace(
          /\s/g,
          "-"
        )}-${moment().format("YYYY-MM-DD_h:msa")}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectUrl(url);
      })
      .catch((e) => console.log("error message", e.message))
      .finally((e) => setLoading(false));
  };
  console.log("the pagination response new grid", dataGrid);

  const changeActivePeriod = (item) => {
    console.log("the change active period item", item, activeReport);
    setActivePeriod(item);
    fetchActivePeriodData(item, activeReport);
  };

  const changePeriodType = (periodType) => {
    setActivePeriodType(periodType);
    let activePeriod = periodTypeGroup[periodType]?.slice(-1)?.at(0);
    if (activePeriod) {
      changeActivePeriod(activePeriod);
    }
  };

  const onReportChange = (selectedOption) => {
    setActiveReport(selectedOption);
    fetchActivePeriodData(activePeriod, selectedOption);
  };

  console.log("the datagrid", dataGrid);
  console.log(
    "the periodTypes and periodTypeGroup and activePeriodType",
    periodTypes,
    periodTypeGroup,
    activePeriodType,
    activePeriod
  );
  console.log("the startend and endDate", dateRange, dateRange2);

  return (
    <div
      className="mb-6 shadow-md sm:rounded-lg mt-4 p-6"
      style={{
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%), 0 -1px 6px -1px rgb(0 0 0 / 10%)",
      }}
    >
      {/* <div className='mb-6 shadow-md sm:rounded-lg mt-4 p-6' style={{boxShadow:"0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%), 0 -1px 6px -1px rgb(0 0 0 / 10%), 0 -2px 4px -2px rgb(0 0 0 / 10%)"}}></div> */}
      {options === null && <EmptyRecord />}
      {options && (
        <>
          <div className="flex-col">
            <div className="border-bottom flex items-center rounded-md py-2 justify-between">
              <div className="tags sm:mb-0">
                {periodTypes?.map((period, index) => {
                  const active = period === activePeriodType ? "active" : "";
                  return (
                    <TagFilterItem
                      key={index}
                      period={period}
                      active={active}
                      onClick={() => changePeriodType(period)}
                      className={`p-2 rounded-lg text-center font-semibold ${
                        active
                          ? "bg-primary text-white"
                          : "bg-gray-500 text-white"
                      }`}
                    />
                  );
                })}
              </div>

              <div className="flex gap-2">
                <button
                  disabled={dataGrid?.length > 0 ? false : true}
                  onClick={exportData}
                  className={`${
                    dataGrid?.length > 0
                      ? "bg-primary "
                      : "bg-gray-300 text-black"
                  }hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center`}
                  style={{ borderRadius: "1rem" }}
                >
                  <DownloadIcon width={16} height={16} stoke="#ffffff" />
                  <h3 className="ml-2 hidden lg:block text-12">
                    <span className="">Export</span>
                  </h3>
                </button>
                <Select
                  key={menuId} // Forces re-render on page change
                  className="hidden lg:block"
                  classNamePrefix="custom-select-option"
                  components={{
                    Option: ReportOption,
                    SingleValue: ReportOptionValue,
                  }}
                  value={activeReport}
                  isClearable={false}
                  onChange={onReportChange}
                  options={reportOptions}
                />
              </div>
              {/* </div> */}
            </div>
            <Select
              key={menuId} // Forces re-render on page change
              className="md:block lg:hidden text-sm my-3"
              classNamePrefix="custom-select-option"
              components={{
                Option: ReportOption,
                SingleValue: ReportOptionValue,
              }}
              // value={activeReport}
              isClearable={false}
              onChange={onReportChange}
              options={reportOptions}
            />
          </div>

          <div className="">
            {activePeriodType !== "Custom" && (
              <div className="period-list flex flex-wrap justify-between mt-6">
                {periodTypeGroup &&
                  periodTypeGroup[activePeriod?.period_type]?.map((item) => {
                    let isActivePeriodItem = item.ID === activePeriod.ID;
                    return (
                      <button
                        onClick={(e) => changeActivePeriod(item)}
                        className={classNames(
                          "flex p-2 flex-col cursor-pointer",
                          {
                            "bg-primary text-white rounded-md shadow":
                              isActivePeriodItem,
                          }
                        )}
                      >
                        <span className="text-sm font-normal md:text-lg md:font-normal">
                          {item.period_l1}
                        </span>
                        <span className="font-bold text-sm">
                          {item.period_l2}
                        </span>
                      </button>
                    );
                  })}
              </div>
            )}

            <div className="mt-6">
              {activePeriodType !== "Custom" && (
                <h2 className="font-medium pb-4">
                  {activePeriod?.period_name}
                </h2>
              )}

              {/* add customized date selection here */}
              {activePeriodType === "Custom" && (
                <div className="block md:flex md:space-x-5 items-center">
                  <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none">
                    <ReactDatePicker
                      selectsRange={false}
                      minDate={minDate}
                      maxDate={maxDate}
                      wrapperClassName="bg-transparent focus:outline-none"
                      dateFormat={"MMM, d"}
                      selected={dateRange}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      placeholderText="Start date"
                      clearButtonClassName="text-primary hover:bg-primary rounded-md"
                      isClearable={true}
                      clear="close"
                    />
                    <div className="flex-1 pr-2">
                      <CalendarIcon width={20} height={20} fill="#9e9e9e" />
                    </div>
                  </div>

                  <h2>-</h2>

                  <div className="flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none mb-5 md:mb-0">
                    <ReactDatePicker
                      selectsRange={false}
                      minDate={dateRange ?? minDate}
                      maxDate={maxDate}
                      wrapperClassName="bg-transparent focus:outline-none"
                      dateFormat={"MMM, d"}
                      selected={dateRange2}
                      onChange={(update) => {
                        setDateRange2(update);
                      }}
                      placeholderText="End date"
                      clearButtonClassName="text-primary hover:bg-primary rounded-md"
                      isClearable={true}
                      clear="close"
                    />
                    <div className="flex-1 pr-2">
                      <CalendarIcon width={20} height={20} fill="#9e9e9e" />
                    </div>
                  </div>

                  <button
                    disabled={!dateRange && !dateRange2 ? true : false}
                    onClick={() => {
                      if (
                        moment(dateRange)._isValid &&
                        moment(dateRange2)._isValid
                      ) {
                        const dateFilter = {
                          startDate: moment(dateRange).format("yyyy-MM-D"),
                          endDate: moment(dateRange2).format("yyyy-MM-D"),
                        };
                        setFilterDateRange(dateFilter);
                        filterByDateRange(dateFilter);
                      }

                      if (
                        !moment(dateRange)._isValid &&
                        !moment(dateRange2)._isValid
                      ) {
                        setFilterDateRange(null);
                      }
                    }}
                    className={`${
                      dateRange && dateRange2
                        ? "bg-primary "
                        : "bg-gray-300 text-black"
                    }hover:bg-primary text-white tracking-normal text-center font-semibold text-xs py-[10px] px-4 rounded-lg inline-flex items-center`}
                    // style={{ borderRadius: "1rem" }}
                  >
                    <h3 className="text-sm">
                      {loadingPeriodData ? (
                        <span className="">loading</span>
                      ) : (
                        <span className="">Search</span>
                      )}
                    </h3>
                  </button>
                </div>
              )}

              <div className="mt-4">
                <DataTable1
                  loading={loadingPeriodData}
                  setLoading={setLoadingPeriodData}
                  setGrid={(grid) => setDataGrid(grid)}
                  dataGridRes={dataGridRes}
                  rows={dataGrid || []}
                  activePeriod={activePeriod}
                  headers={gridKeys}
                  headerProps={activeReport}
                  schemaId={activePeriod?.ID}
                  dateFilter={filterDateRange}
                  setNewPage = {(page) => setActivePage(page)}
                  page={activePage}
                  currentDate={currentDate}
                />
                {console.log(
                  "the activeperiod and schemaId",
                  activePeriod,
                  activePeriod?.ID
                )}

                {/* <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {gridKeys?.map((str, idx) => (
                      <th scope="col" className="px-4 py-3 text-xs" key={idx}>
                        {str}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loadingPeriodData && 
                    <div className="grid place-items-center place-content-center h-20">
                      <span className="">Loading...</span>
                      <Spinner width={32} height={32} fill="#aaaaaa" />
                    </div>
                    }
                  {!loadingPeriodData && <>
                  {dataGrid?.map((line, key) => {
                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        {gridKeys?.map((str, idx) => (
                          <td className="px-4 py-4 text-xs">
                            <TransformValue value={line[str]} />
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                  {(dataGrid?.length === 0 || dataGrid == null) && (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-4 py-4 text-md">
                        <span className="flex justify-center font-bold">
                          No record available.
                        </span>
                      </td>
                    </tr>
                  )}
                  </>}
                </tbody>
              </table> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WithLoader(DateNotificationList1);
