import React, { useContext, useEffect, useRef, useState } from "react";
import icons from "../icons/component";
import PlusIcon from "../icons/PlusIcon";
import PlusRound from "../icons/PlusRound";
import actions from "../actions";
import uuid from "react-uuid";
import EmptyIcon1 from "../icons/EmptyIcon1";
import EmptyIcon2 from "../icons/EmptyIcon2";
import styled, { ThemeContext } from "styled-components";
import { Dialog } from "@headlessui/react";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import renderComponent from "../renderComponent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DrillModalDialog from "./DrillModalDialog";
import { appendQueryToUri } from "../util/generalUtil";
import { QuickNotificationReport } from "../constants/variables";

const QuickNotificationCard = styled.div`
  background: ${({ theme }) => theme?.colors?.accent};
`;

const iconMap = {
  "Undeployed Tenders": "UndeployedTenderIcon",
  "Active Tenders": "ActiveTenderIcon",
  "Closing Tenders": "ClosingTenderIcon",
  "Closed Tenders": "ClosedTenderIcon",
};

// const ModalDialog = ({isOpen,item,closeModal}) => {
//     return (
//         <Dialog  as="div"
//         className="fixed inset-0 z-10 overflow-y-auto"
//         onClose={closeModal}
//         open={isOpen}>
//       <div className="min-h-screen h-full px-4 text-center">
//       <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
//           <span
//             className="inline-block h-screen align-middle"
//             aria-hidden="true"
//           >
//             &#8203;
//           </span>
//         <div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">

//           {item && renderComponent(item)}
//           <div className="mt-4 flex justify-end">
//             <button
//               type="button"
//               className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
//               onClick={closeModal}>
//               Close
//             </button>
//           </div>
//         </div>
//       </div>
//     </Dialog>
//     )
// }

function QuickNotificationList1_2({ setLoading, options, children, onClick }) {
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const gridRef = useRef(null);
  const cardLength = options?.length ?? 0;

  console.log("new options", options);

  // useEffect(() => {
  //   const el = gridRef.current
  //   console.log("gridContainer",el)
  //   el.style.setProperty('--total-grid', options.length);
  // },[options.length])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: cardLength > 2 ? 2 : cardLength,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: cardLength > 2 ? 2 : cardLength,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: cardLength > 1 ? 1 : cardLength,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: cardLength > 1 ? 1 : cardLength,
          dots: true,
        },
      },
    ],
  };

  const showDetail = (item) => {
    console.log("item", item);

    setLoading(true);
    let postData = {
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = item;
        setModalData({
          ...res,
          layoutid,
          targetid,
          drillid,
          target: QuickNotificationReport,
        });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}
      <div className="mb-4">
        <Slider {...settings}>
          {" "}
          {options &&
            options?.map((item, idx) => {
              let { name, value, box_value, icon } = item;
              let QuickIcon = iconMap[name];
              return (
                <div key={idx}>
                  <QuickNotificationCard
                    onClick={() => showDetail(item)}
                    className="shadow-md cursor-pointer px-4 rounded-lg h-44 grid content-around mx-2 mb-2"
                  >
                    {icons[QuickIcon] ? (
                      React.createElement(icons[QuickIcon], {
                        width: 28,
                        height: null,
                        fill: theme?.colors?.primary,
                      })
                    ) : (
                      <EmptyIcon2
                        width={26}
                        height={null}
                        fill={theme?.colors?.primary}
                      />
                    )}
                    <div className="">
                      {/* <span className="xl:text-2xl lg:text-lg  inline-block">{box_value ?? value}</span> */}
                      <span
                        className="xl:text-lg lg:text-lg  inline-block"
                        dangerouslySetInnerHTML={{ __html: box_value ?? value }}
                      ></span>
                      <h2 className="font-semibold text-sm uppercase">
                        {name}
                      </h2>
                    </div>
                  </QuickNotificationCard>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default WithLoader(QuickNotificationList1_2);
